export function Home(){
    return (
        <div className="default-container">
            <h2 id="verse" >
            <marquee direction="left">
      ✨ இயேசு: நானே வழியும், சத்தியமும், ஜீவனுமாயிருக்கிறேன் - யோவான் 14 : 6 🕊️
          </marquee>
  </h2>
<div className=" container mt-5 embed-responsive embed-responsive-16by9">
<iframe className="embed-responsive-item" src="https://www.youtube.com/embed/2khiVsT_UNY?rel=0" allowFullScreen></iframe>
</div>

<br/><br/><br/>
<footer className="footer">
<div>
  <div>
    <h3 id="contact">Contact-us 📧</h3>
    
    <span className="text-muted" >alex.harvesters@gmail.com </span>
   
  </div>
</div>
</footer>
        </div>
    )
}